:root {
  --dark-navy: #020c1b;
  --light-navy: #112240;
  --lightest-navy: #182f56;
  --lightest-slate: #CCD6F6;
  --dark-slate: #495670;
  --slate: #8892b0; 
  
  --navy: #0A192F;
  --green: #4EC7B0;
  --light-slate: #a8b2d1;

  --font-inter: "__inter_20b187","__inter_Fallback_20b187";
  --font-sans: var(--font-inter),"ui-sans-serif","system-ui","-apple-system","BlinkMacSystemFont","Segoe UI","Roboto","Helvetica Neue","Arial","Noto Sans","sans-serif";
  --font-mono: 'SF Mono','Fira Code','Fira Mono','Roboto Mono', monospace;
}

.App {
  text-align: center;
  font-family: var(--font-sans);
} 

body {
  background-color: var(--navy);
  padding: 0;
  margin: 0;  
}

.text {
  color: var(--slate);
  font-size: 18px;
  font-family: var(--font-sans);
}
