.navbar {
    width: 100%;
    height: 100px;
}

.links {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--lightest-slate);
}

.displayLink {
  font-family: var(--font-mono)
}

.displayLinkNumber {
  color: var(--green);
}

.navbar a {
  color: var(--lightest-slate);
  text-decoration: none;
  margin: 20px;
  font-size: 20px;
}

.navbar-align {
  position: absolute;
  right: 5%;
}

.toggleButton {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.toggleButton svg {
  font-size: 50px;
}

.toggleButton button {
  margin-right: 20px;
  background-color: transparent;
  border: none;
  color: var(--lightest-slate);
  cursor: pointer;
}

#open {
  height: 100vh;
}

#open .links {
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 100px;
}

#open a {
  width: 100%;
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .toggleButton {
    display: flex;
  }

  #close a {
    display: none;
  }

  .navbar-align {
    position: unset;
  }
}

@media only screen and (min-width: 600px) {
  .toggleButton {
    display: none;
  }

  .hiddenLinks {
    display: none;
  }

  #open {
    height: 100px;
  }

  #open .links {
    flex-direction: row;
  }
}
