.photos {
    width: 100%;
    margin: auto;
    text-align: center;
}

.galleryHeader {
  color: var(--lightest-slate);
  font-size: 40px;
  font-weight: bold;
  margin-top: 0px;
  margin-bottom: 15px;
}

.content {
    color: var(--slate);
    font-size: 18px;
    justify-content: center;
    width: 60%;
    margin: auto;
    margin-bottom: 20px;
}
